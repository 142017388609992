import React from 'react';
import Flex from 'styled-flex-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

import Link from '../components/Link';
import { H3 } from '../components/common/H.style';

// ArticleTemplateを使う場所でhtmlかhtmlAstのどちらかを与えること
const ArticleTemplate = ({ topLink, date, title, previous, next, html, htmlAst }) => (
  <>
    <div
      css={`
        margin: 5px 0;
      `}
    >
      <Link to={topLink}>
        <span
          css={`
            font-size: 12px;
            color: #f0862e;
          `}
        >
          一覧へ戻る
        </span>
      </Link>
    </div>
    <div
      css={`
        margin: 5px 0;
      `}
    >
      <span
        css={`
          font-size: 12px;
          color: #666666;
        `}
      >
        {date}更新
      </span>
    </div>
    <H3>{title}</H3>
    <Flex
      justifyBetween={!!previous}
      justifyEnd={!previous}
      wrap="true"
      css={`
        margin: 10px 0;
      `}
    >
      {previous && (
        <Link to={previous.frontmatter.path}>
          <Flex>
            <FontAwesomeIcon
              icon={faChevronLeft}
              style={{ fontSize: 12, marginRight: 10 }}
              color="#f0862e"
            />
            <span
              css={`
                font-size: 12px;
                color: #f0862e;
              `}
            >
              {previous.frontmatter.title}
            </span>
          </Flex>
        </Link>
      )}
      {next && (
        <Link to={next.frontmatter.path}>
          <Flex>
            <span
              css={`
                font-size: 12px;
                color: #f0862e;
              `}
            >
              {next.frontmatter.title}
            </span>
            <FontAwesomeIcon
              icon={faChevronRight}
              style={{ fontSize: 12, marginRight: 10 }}
              color="#f0862e"
            />
          </Flex>
        </Link>
      )}
    </Flex>
    {htmlAst ? htmlAst : <div dangerouslySetInnerHTML={{ __html: html }} />}
  </>
);

export default ArticleTemplate;
